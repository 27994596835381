import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/layouts/MdxLayout.js";
import SEO from "../components/Seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Washington state wine and cider tasting. Archibald James tasting rooms." description="Washington state wine and cider tasting at Archibald James." mdxType="SEO" />
    <article className="mx-auto prose prose-xl">
      <h1 {...{
        "id": "archibald-james-tasting-rooms"
      }}>{`Archibald James Tasting Rooms`}</h1>
      <p>{`Currently we're serving products exclusively at `}<a parentName="p" {...{
          "href": "/leavenworth"
        }}>{`our tasting room in Leavenworth, WA`}</a>{`.`}</p>
      <p>{`Check our list of `}<a parentName="p" {...{
          "href": "/stockists"
        }}>{`stockists`}</a>{` to find us nearby or `}<a parentName="p" {...{
          "href": "/club"
        }}>{`join our club`}</a>{` to have Archibald James delivered.`}</p>
      <p>{`Not nearby? `}<a parentName="p" {...{
          "href": "/club"
        }}>{`Join our club`}</a>{` to have our products delivered to your door.`}</p>
    </article>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      